import { ENV } from '@/constants/config'

// TODO: delete this file if landing page is fully implemented!
const redirectAfterLogout = (route, router) => {
  // default
  // router.replace('/')
  // if (route.name.includes('admin')) {
  //   router.replace({ name: 'auth', query: { tab: 'login' } })
  // }

  // for landing-page
  window.location.replace(ENV.URL_DIETELA_LANDING_PAGE)
}

const redirectDetailProgram = (route, router, isAuthenticated, data) => {
  if (data?.name?.toLowerCase().includes('enterprise')) {
    if (isAuthenticated) {
      router.push({
        name: 'client.enterpriseService',
      })
      return
    }

    // Redirect to extenal program page
    return window.location.href = ENV.URL_DIETELA_LANDING_PAGE + '/layanan-enterprise'
  }
  if (isAuthenticated) {
    router.push({
      name: 'client.program.detail',
      params: {
        id: data?.slug ? data?.slug : data?.id,
      },
    })
    return
  }

  // Redirect to extenal program page
  return window.location.href = ENV.URL_DIETELA_LANDING_PAGE + '/program/' + data?.slug ?? data?.id
}

const redirectHeaderProgram = (route, router, isAuthenticated, item) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const handleScrollTopIfSamePath = (productId) => {
    if (productId === route.params.id) {
      scrollToTop()
      return true
    }
    return false
  }
  if (isAuthenticated) {
    const hasCustomRouter = item.customRouter
    const isSamePathCustomRouter = route.name === item.customRouter?.name
    if (hasCustomRouter && isSamePathCustomRouter) {
      scrollToTop()
      return
    }
    if (hasCustomRouter) {
      router.push(item.customRouter)
      return
    }

    const isSamePath = handleScrollTopIfSamePath(item.id)
    if (isSamePath) return

    router.push({
      name: 'client.program.detail',
      params: { id: item?.slug ? item?.slug : item?.id },
    })
    return
  }

  // Redirect to extenal program page
  return window.location.href = ENV.URL_DIETELA_LANDING_PAGE + '/program/' + item?.slug ?? item?.id
}

const redirectHeaderPricing = (router, isAuthenticated) => {
  if (isAuthenticated) {
    router.push({
      name: 'client.pricing',
    })
    return
  }
  // Redirect to extenal pricing page
  window.location.replace(ENV.URL_DIETELA_LANDING_PAGE + '/pricing')
}

const redirectLinkMainMenu = (isAuthenticated) => {
  if (isAuthenticated) {
    return { route: { name: 'client.select-program' } }
  }
  // Redirect to extenal pricing page
  return { href: ENV.URL_DIETELA_LANDING_PAGE + '/program' }
}

export const flags = {
  redirectAfterLogout,
  redirectDetailProgram,
  redirectHeaderProgram,
  redirectHeaderPricing,
  redirectLinkMainMenu,
}