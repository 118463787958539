const clientDetail = async(axios, { clientId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/${clientId}/client-detail`)
}

const getFormOptConditions = async(axios, { age }) => {
  return axios.get(`/v1/nutritionist/meal-plans/form-opt-conditions?age=${age}`)
}

const getFormOptIntake = async(axios) => {
  return axios.get('/v1/nutritionist/meal-plans/form-opt-intake')
}

const getMealPlanData = async(axios, { clientId, programId, month }) => {
  return axios.get(`/v1/nutritionist/meal-plans/data?clientId=${clientId}&programId=${programId}&month=${month}`)
}

const updateMealPlanDraft = async(axios, { payload }) => {
  return axios.post('/v1/nutritionist/meal-plans/draft', payload)
}

const getBasicData = async(axios, { clientId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/basic-data/${clientId}`)
}

const getFormOptBodyConditions = async(axios) => {
  return axios.get('/v1/nutritionist/meal-plans/form-opt-body-conditions')
}

const getDefaultItem = async(axios) => {
  return axios.get('/v1/nutritionist/meal-plans/default-item')
}

const getMealGlossaries = async(axios, { filter, q, page, perpage }) => {
  return axios.get('/v1/nutritionist/meal-plans/meal-glossaries', {
    params: {
      filter: filter || null,
      q: q || null,
      page: page || 1,
      perpage: perpage || 10,
    },
  })
}

const postUpload = async(axios, formData) => {
  return axios.post('/v1/nutritionist/meal-plans/upload', formData)
}

const getMenuRecommendationWeekDays = async(axios, { clientId, programId, month }) => {
  return axios.get(`/v1/nutritionist/meal-plans/menu-recommendation/weeks-days?clientId=${clientId}&programId=${programId}&month=${month}`)
}

const getMenuRecommendationFormDays = async(axios, { clientId, programId, month }) => {
  return axios.get(`/v1/nutritionist/meal-plans/menu-recommendation/form-days?clientId=${clientId}&programId=${programId}&month=${month}`)
}

const getMenuRecommendationCalendar = async(axios, { clientId, programId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/menu-recommendation/calendar?clientId=${clientId}&programId=${programId}`)
}

const getMenuRecommendationPreview = async(axios, { id }) => {
  return axios.get(`/v1/nutritionist/meal-plans/menu-recommendation/preview/${id}`)
}

const getMenuRecommendationDuplicationData = async(axios, { clientId, programId, month, parentDay, targetDay }) => {
  return axios.get(`/v1/nutritionist/meal-plans/menu-recommendation/duplication-data?clientId=${clientId}&programId=${programId}&month=${month}&parentDay=${parentDay}&targetDay=${targetDay}`)
}

const postMenuRecommendationDuplicate = async(axios, { payload }) => {
  return axios.post('/v1/nutritionist/meal-plans/menu-recommendation/duplicate', payload)
}

const putMenuRecommendationDuplication = async(axios, { payload }) => {
  return axios.put('/v1/nutritionist/meal-plans/menu-recommendation/duplication', payload)
}

const postMealPlanDuplicate = async(axios, payload) => {
  // const payload = {
  //   "id": "90ae8cc2-3d28-406a-8174-ba6dbb83e4b3",
  //   "month": 2,
  //   "mealPlan": 1,
  //   "nutritionalProfile": 1
  // }
  return axios.post('/v1/nutritionist/meal-plans/duplicate', payload)
}

export const reqNutri_mealPlans_downloadFilename = async(
  axios, {
    programId,
    month,
    clientId,
  }) => {
  return axios.get('/v1/nutritionist/meal-plans/download/filename', {
    params: {
      programId,
      month,
      clientId,
    },
  })
}

export const reqNutri_mealPlans_download = async(
  axios, {
    programId,
    month,
    clientId,
  }, {
    responseType,
  }) => {
  return axios.get('/v1/nutritionist/meal-plans/download', {
    params: {
      programId,
      month,
      clientId,
    },
    responseType,
  })
}

export const reqNutri_mealPlans_menuRecommendation_isSkip = async(axios, payload) => {
  return axios.post('/v1/nutritionist/meal-plans/menu-recommendation/is-skip', payload)
}

export const reqNutriMealPlan = {
  clientDetail,
  getFormOptConditions,
  getFormOptIntake,
  getMealPlanData,
  updateMealPlanDraft,
  getBasicData,
  getFormOptBodyConditions,
  getDefaultItem,
  getMealGlossaries,
  postUpload,
  getMenuRecommendationWeekDays,
  getMenuRecommendationFormDays,
  getMenuRecommendationCalendar,
  getMenuRecommendationPreview,
  getMenuRecommendationDuplicationData,
  postMenuRecommendationDuplicate,
  putMenuRecommendationDuplication,
  postMealPlanDuplicate,
}